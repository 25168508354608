import styled from "styled-components"

import { getDateRangeString } from "src/components/ResponseService/AssistServices/assistServiceUtil"
import { CallAssistPill } from "src/components/ResponseService/AssistServices/CallAssistPill"
import { GuestSourceIcon } from "src/components/ResponseService/AssistServices/GuestSourceIcon"
import { TCallAssistGuestsResponse } from "src/data/callAssist/types/callAssistTypes"
import { useTranslate } from "src/i18n/useTranslate"
import { MDialog } from "src/ui/Dialog/MDialog"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { Maybe } from "src/utils/tsUtil"

export type TGuestDetails = TCallAssistGuestsResponse["guests"][number] & {
  open: boolean
}

export function GuestDetailsDialog({
  guestDetails,
  onClose,
}: {
  guestDetails: Maybe<TGuestDetails>
  onClose: () => void
}) {
  const { t, langKeys } = useTranslate()
  const guest = guestDetails?.guest

  return (
    <MDialog title={guest?.name} open={!!guestDetails?.open} onClose={onClose}>
      {guest && (
        <DetailsBox>
          <MText>{t(langKeys.booking_dates)}</MText>
          <MText textAlign="end">{getDateRangeString(guestDetails)}</MText>

          <MText>{t(langKeys.phone)}</MText>
          <MText textAlign="end">{guest.phone_number}</MText>

          <MText>{t(langKeys.home)}</MText>
          <MText textAlign="end">{guestDetails.home_name}</MText>

          <MText>{t(langKeys.guest_source)}</MText>
          <MText textAlign="end">
            <GuestSourceIcon source={guest.source} />
          </MText>

          <MText>{t(langKeys.call_assist)}</MText>
          <MText textAlign="end">
            <CallAssistPill enabled={guestDetails.call_assist_active} />
          </MText>
        </DetailsBox>
      )}
    </MDialog>
  )
}
const DetailsBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.XS};
`
