import { useState } from "react"
import styled from "styled-components"

import {
  getDateRangeString,
  TCallAssistGuest,
} from "src/components/ResponseService/AssistServices/assistServiceUtil"
import { useFetchCallAssistGuestlist } from "src/data/callAssist/queries/callAssistQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import { useTranslate } from "src/i18n/useTranslate"
import { Routes } from "src/router/routes"
import { TextButton } from "src/ui/Button/TextButton"
import { colorsLegacy } from "src/ui/colors"
import { GridTable } from "src/ui/GridTable/GridTable"
import Apartments from "src/ui/icons/apartment-three-buildings.svg"
import { InternalLink } from "src/ui/Link/InternalLink"
import { MBadge } from "src/ui/MBadge/MBadge"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"

import { CallAssistPill } from "./CallAssistPill"
import { GuestDetailsDialog, TGuestDetails } from "./GuestDetailsDialog"
import { GuestSourceIcon } from "./GuestSourceIcon"
import { PhoneNumber } from "./PhoneNumber"

export function CallAssistGuestTable() {
  const { t, langKeys } = useTranslate()
  const { orgId } = useOrganization()
  const fetchCallAssistGuestlist = useFetchCallAssistGuestlist({ orgId })
  const [guestDetails, setGuestDetails] = useState<TGuestDetails | null>(null)

  if (fetchCallAssistGuestlist.error) {
    // handle error
    return null
  }

  if (fetchCallAssistGuestlist.isLoading) {
    // handle loading state
    return null
  }

  const guestList = fetchCallAssistGuestlist.data.guests

  if (guestList.length === 0) {
    return <Placeholder />
  }

  const header = [
    <div key={"booking-dates"}>
      {t(langKeys.placeholder_web, { text: "Booking dates" })}
    </div>,
    <div key={"guest"}>{t(langKeys.guest)}</div>,
    <div key={"home"}>{t(langKeys.home)}</div>,
    <div key={"phone"}>{t(langKeys.phone)}</div>,
    <div key={"guest-source"}>{t(langKeys.guest_source)}</div>,
    <div key={"call-assist"}>{t(langKeys.call_assist)}</div>,
  ]

  const rows = guestList.map((d) => (
    <CAGuestRow
      key={d.guest.guest_id}
      data={d}
      onGuestClick={(g) => setGuestDetails({ ...g, open: true })}
    />
  ))
  const mobileRows = guestList.map((d) => (
    <CAGuestRowMobile
      key={d.guest.guest_id}
      data={d}
      onGuestClick={(g) => setGuestDetails({ ...g, open: true })}
    />
  ))

  return (
    <GridTable
      rows={rows}
      mobileRows={mobileRows}
      responsiveModeTitle={t(langKeys.guest, { count: 2 })}
      templateColumns={`repeat(${header.length}, auto)`}
      header={header}
    >
      <GuestDetailsDialog
        guestDetails={guestDetails}
        onClose={() =>
          setGuestDetails((prev) => (!!prev ? { ...prev, open: false } : prev))
        }
      />
    </GridTable>
  )
}

function CAGuestRow({
  data,
  onGuestClick,
}: {
  data: TCallAssistGuest
  onGuestClick: (g: TGuestDetails) => void
}) {
  return (
    <>
      <MText>{getDateRangeString(data)}</MText>

      <div>
        <div>
          <TextButton onClick={() => onGuestClick({ ...data, open: true })}>
            {data.guest.name}
          </TextButton>
        </div>
      </div>

      <InternalLink to={Routes.Home.location(data.guest.home_id)}>
        {data.home_name}
      </InternalLink>

      <PhoneNumber
        nbr={data.guest.phone_number}
        active={data.call_assist_active}
      />

      <div>
        <GuestSourceIcon source={data.guest.source} />
      </div>

      <div>
        <MText>
          <CallAssistPill enabled={data.call_assist_active} />
        </MText>
      </div>
    </>
  )
}

function CAGuestRowMobile({
  data,
  onGuestClick,
}: {
  data: TCallAssistGuest
  onGuestClick: (g: TGuestDetails) => void
}) {
  const { t, langKeys } = useTranslate()

  return (
    <CAGuestItemGrid>
      <MText>
        <TextButton onClick={() => onGuestClick({ ...data, open: true })}>
          {data.guest.name}
        </TextButton>
      </MText>
      <MText textAlign="end">{data.home_name}</MText>
      <MText>{t(langKeys.booking_dates)}</MText>
      <MText textAlign="end">{getDateRangeString(data)}</MText>
      <MText>{t(langKeys.call_assist)}</MText>
      <MText textAlign="end">
        {data.call_assist_active ? (
          <MBadge>{t(langKeys.on)}</MBadge>
        ) : (
          <MBadge color="neutral">{t(langKeys.off)}</MBadge>
        )}
      </MText>
    </CAGuestItemGrid>
  )
}

const CAGuestItemGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${spacing.S};
  max-width: 55ch;
  border-bottom: 1px solid ${colorsLegacy.divider};
  padding-bottom: ${spacing.M};
`

function Placeholder() {
  const { t, langKeys } = useTranslate()
  return (
    <PlaceholderBox>
      <ApartmentsImg />
      <MText variant="heading2" marginBottom={spacing.XS}>
        {t(langKeys.no_guests_found)}
      </MText>
      <MText variant="body" color="secondary" textAlign="center">
        {t(langKeys.ca_configure_guests)}
      </MText>
    </PlaceholderBox>
  )
}

const PlaceholderBox = styled.div`
  display: grid;
  max-width: 950px;
  place-items: center;
`

const ApartmentsImg = styled(Apartments)`
  max-width: 150px;
`
