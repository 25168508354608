import { TCallAssistGuestsResponse } from "src/data/callAssist/types/callAssistTypes"

export type TCallAssistGuest = TCallAssistGuestsResponse["guests"][number]

export function getDateRangeString(data: TCallAssistGuest) {
  const startDate = data.guest.checkin_time
    ? new Date(data.guest.checkin_time).toLocaleDateString("en", {
        day: "2-digit",
        month: "short",
      })
    : "n/a"
  const endDate = data.guest.checkout_time
    ? new Date(data.guest.checkout_time ?? "").toLocaleDateString("en", {
        day: "2-digit",
        month: "short",
      })
    : "n/a"
  return `${startDate} - ${endDate}`
}
