import { CallAssistGuestTable } from "src/components/ResponseService/AssistServices/CallAssistGuestTable"
import { HREF_MINUT_HELP_CALL_ASSIST } from "src/constants/hrefs"
import { useTranslate } from "src/i18n/useTranslate"
import { MainView } from "src/ui/Layout/MainView"
import { LearnMore } from "src/ui/Link/LearnMore"

export function CallAssistConfigure() {
  const { t, langKeys } = useTranslate()

  return (
    <MainView
      title={t(langKeys.call_assist)}
      titleBarProps={{
        description: (
          <div>
            {t(langKeys.activate_call_assist_about_body_1)}{" "}
            <LearnMore href={HREF_MINUT_HELP_CALL_ASSIST} />
          </div>
        ),
      }}
    >
      <CallAssistGuestTable />
    </MainView>
  )
}
