import { useTranslate } from "src/i18n/useTranslate"
import { MBadge } from "src/ui/MBadge/MBadge"

export function CallAssistPill({ enabled }: { enabled: boolean }) {
  const { t, langKeys } = useTranslate()

  if (enabled) {
    return <MBadge>{t(langKeys.on)}</MBadge>
  }
  return <MBadge color="neutral">{t(langKeys.off)}</MBadge>
}
