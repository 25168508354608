import { TCallAssistGuest } from "src/components/ResponseService/AssistServices/assistServiceUtil"
import { useFetchIntegrations } from "src/data/integrations/queries/integrationQueries"
import { useOrganization } from "src/data/organizations/hooks/useOrganization"
import MinutIcon from "src/ui/icons/minut-icon.svg?inline"

export function GuestSourceIcon({
  source,
}: {
  source: TCallAssistGuest["guest"]["source"] | undefined
}) {
  const { orgId } = useOrganization()
  const integrationsQuery = useFetchIntegrations({ orgId })

  if (source === "minut") {
    return <img src={MinutIcon} alt={`${source} icon`} width={24} />
  }
  if (integrationsQuery.isLoading) {
    return null
  }
  if (integrationsQuery.isError) {
    return null
  }
  const iconUrl = integrationsQuery.data.integrations.find(
    (i) => i.integration_identifier === source
  )?.icon
  return <img src={iconUrl} alt={`${source} icon`} width={24} />
}
